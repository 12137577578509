'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

export default function PageNotFound() {
  const page = usePathname()

  return (
    <div className="h-100 flex flex-col items-center justify-between p-10 text-center">
      <h1 className="text-4xl font-bold text-black">404</h1>
      <h4 className="font-bold text-black">
        Ops, a página que você está procurando não existe.
      </h4>
      <h3>
        Caso esteja procurando por algum post, visite a página de notícias
      </h3>
      <Link
        href={`/todas-noticias?search=${page
          .replace(/\//g, '')
          .replace(/-/g, ' ')}`}
        className="btn min-h-8 my-4 mb-4 max-h-8 rounded-none bg-amarelo px-3 py-1 text-xs uppercase text-black hover:bg-amarelo"
      >
        Notícias
      </Link>
      <span>ou</span>
      <Link
        href="/"
        className="btn min-h-8 my-4 mb-4 max-h-8 rounded-none bg-amarelo px-3 py-1 text-xs uppercase text-black hover:bg-amarelo"
      >
        voltar para página princial
      </Link>
    </div>
  )
}
